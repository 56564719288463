/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
      <div className="min-h-screen bg-gray-200 flex flex-col gap-5">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="px-6 mx-auto w-full flex flex-col md:flex-row gap-x-24">
        <div id="sidebar" className="w-full md:w-2/12 text-xs">


<StaticImage alt="SignTech Advertising Services" width={180} height={180} className="mb-20" src="../images/logo.png" />

<p>
With more than 35 years of experience in the field of advertising, Sign-Tech Advertising Services Pvt. Ltd. 
does not just create signage for your company; it creates relationships with you and for you.
</p>

<div>
<ul className="pt-10 pb-10 font-bold">
    <li className="mt-2 mb-2 py-2">85B Srimath Anagarika Dharmapala Mw, Colombo 07.</li>
    <li className="mt-2 mb-2 py-2 border-t-2 border-b-2 border-gray-600">011 233 0326 | 077 246 6456</li>
    <li className="mt-2 mb-2 py-2">sales@signtechads.com</li>
</ul>

</div>

        </div> 




        <div className="w-full md:w-10/12 border-t-2 border-gray-300">
        {children}
        </div>  

        </main>
      <footer className="p-2 text-xs text-red-800 mt-auto">
        © {new Date().getFullYear()},
        {` `}
        <a href="https://www.signtechads.com">SignTech Advertising Services Pvt. Ltd</a>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
