import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"

// import navData from "../../content/nav.yaml" 

// #region constants

interface MenuItem {
	itemTitle: string,
	itemDesc?: string,
	imgURL?: string
}

interface Menu {
	menuHeader: string, 
	menuItems: Array<MenuItem>
}

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const Nav = () => {
	const [close, setclose] = useState(false)

	const menuMap = (value: Menu)=>{
		return 	<>
					<h4>{value.menuHeader}</h4>
					<ul>{	
						value.menuItems.map(val => (
							<li>{val.itemTitle}</li>
						))
					}</ul>
				</>
	}

	return <div>

    <div className="flex  flex-row-reverse">
		<div className="text-gray-600  uppercase self-end text-xs">
			<ul className="flex flex-wrap flex-row px-8">
				<li className="my-8 mx-4 hover:text-red-900 hover:underline">
				<Link to="/" activeClassName="text-red-900">
					Home
				</Link>
				</li>
				<li className="my-8 mx-4 hover:text-red-900 hover:underline">
				<Link to="/products" activeClassName="text-red-900">
					Products
				</Link>	
				</li>
				<li className="my-8 mx-4 hover:text-red-900 hover:underline">
				<Link to="/clients" activeClassName="text-red-900">
					Clients
				</Link>
				</li>
				<li className="my-8 mx-4 hover:text-red-900 hover:underline">
				<Link to="/about" activeClassName="text-red-900">
					About
				</Link>
				</li>
				<li className="my-8 mx-4 hover:text-red-900 hover:underline">
				<Link to="/contact" activeClassName="text-red-900">
					Contact
				</Link>
				</li>
			</ul>
		</div>
	</div>
 {/**  add the expanded nav here*/ }
	</div>;
}

Nav.propTypes = propTypes;
Nav.defaultProps = defaultProps;
// #endregion

export default Nav;