import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Announcement from "./header/announcement"
import Nav from "./header/nav"

const Header = ({ siteTitle }) => (
  <header className="">
    {/** main call to action */}
    <Nav />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
